// TODO: remove this once we have the correct types
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from "axios"
import dayjs from "dayjs"

import {
  BulkDistributionApi,
  DistributionCampaignReportDto,
  DistributionClientsSearchDto,
  DistributionFilterDto,
  DistributionProjectAnalyticsInfoDto,
  DistributionProjectClientListDto,
  DistributionProjectDatesAnalyticsDto,
  DistributionProjectInfoDto,
  DistributionProjectsAnalyticsDto,
  ProductTypeDto,
  StoreDistributionDetailsDto,
} from "legado-generated-api-client"
import { CampaignReportFilterValues } from "../../component/Communications/ProjectSummary/Tabs/Analytics/Analytics"
import {
  dummyDistributionCampaignReportAdvisor,
  dummyDistributionCampaignReportAll,
  dummyDistributionCampaignReportClient,
} from "../../component/Communications/ProjectSummary/Tabs/data"
import { graphDataStatic } from "../../pages/Communications/utils/analyticsUtils"
import { sleep } from "../../utils/helpers"
import { IFile } from "../api-client/api-types"
import { ApiController } from "../apiController"

/* Communications */
export class BulkDistributionController extends ApiController {
  private apiController: ApiController
  constructor(apiController: ApiController) {
    super()
    this.apiController = apiController
  }

  public async uploadCsvFile(
    file: IFile,
    projectId: string | undefined,
    secondAttempt: boolean = false
  ): Promise<any> {
    if (this.apiController.connected) {
      file.name = file.name.replace(/'/g, "`")
      try {
        const communicationsApi = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })
        const blob = new Blob([file.binaryStr!], {
          type: file.mimeType ?? undefined,
        })
        const formData = new FormData()
        formData.append("file", blob, file.name)
        const response =
          await communicationsApi.apiBulkDistributionProjectProjectIdCsvuploadPut(
            projectId!,
            this.getOptions(true, formData)
          )
        //TODO: remove this once back end removes the 204's https://dev.azure.com/secure-the-file/Application/_workitems/edit/15317
        if (response.status === 204) {
          const errorResponse = {
            id: file.id,
            name: file.name,
            extension: file.extension,
            fileType: "ERROR",
            fileName: "File is empty",
          }
          return [errorResponse]
        }
        return "success"
      } catch (error) {
        const shouldRetry = await this.HandleError(
          error,
          "Could not upload",
          secondAttempt
        )
        if (shouldRetry) return await this.uploadCsvFile(file, projectId, true)
        const errorResponse = {
          id: file.id,
          name: file.name,
          extension: file.extension,
          fileType: "ERROR",
          fileName: (error as AxiosError).response?.data,
        }
        return [errorResponse]
      }
    } else {
      await sleep(500)
      if (file.extension === "csv") {
        const fileResponse = "success"

        return fileResponse
      } else {
        const errorResponse = {
          id: file.id,
          name: file.name,
          extension: file.extension,
          fileType: "ERROR",
          fileName: "Invalid file type",
        }
        return [errorResponse]
      }
    }
  }

  public async deleteCsvCriteria(
    projectId: string,
    secondAttempt: boolean = false
  ): Promise<boolean> {
    if (this.apiController.connected) {
      try {
        const communicationsApi = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })

        await communicationsApi.apiBulkDistributionProjectProjectIdCriteriaRemovePut(
          projectId,
          this.getOptions(true)
        )
        return true
      } catch (error) {
        const shouldRetry = await this.HandleError(
          error,
          "Could not Delete Criteria",
          secondAttempt
        )
        if (shouldRetry) return await this.deleteCsvCriteria(projectId, true)
        return false
      }
    } else {
      await sleep(100)
      if (projectId) {
        return true
      } else {
        return false
      }
    }
  }

  public async postBulkUploadProjectDetails(
    projectId: string,
    storeDistributionDetailsDto: StoreDistributionDetailsDto,
    secondAttempt: boolean = false,
    projects?: DistributionProjectAnalyticsInfoDto[]
  ): Promise<boolean | DistributionProjectInfoDto> {
    if (this.apiController.connected) {
      try {
        const communicationsApi = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })

        await communicationsApi.apiBulkDistributionProjectProjectIdSchedulePut(
          projectId,
          storeDistributionDetailsDto,
          false,
          this.getOptions(true)
        )
        return true
      } catch (error) {
        const shouldRetry = await this.HandleError(
          error,
          "Could not Delete Criteria",
          secondAttempt
        )
        if (shouldRetry)
          return await this.postBulkUploadProjectDetails(
            projectId,
            storeDistributionDetailsDto,
            true
          )
        return false
      }
    } else {
      await sleep(500)
      const updateStaticProject = projects?.find(
        (project) => project.id === projectId
      )
      if (updateStaticProject) {
        return updateStaticProject
      } else {
        const newProject = {
          id: projectId,
          distributionDateTimeAdviser:
            storeDistributionDetailsDto.distributionDateTimeAdviser,
          distributionDateTimeClient:
            storeDistributionDetailsDto.distributionDateTimeClient,
          isSubAccount: storeDistributionDetailsDto.isSubAccount,
          sendNotification: storeDistributionDetailsDto.sendNotification,
          visibility: storeDistributionDetailsDto.visibility,
        } as DistributionProjectInfoDto
        return newProject
      }
    }
  }

  public async deleteBulkUploadProject(
    projectId: string,
    secondAttempt: boolean = false
  ): Promise<any> {
    if (this.apiController.connected) {
      try {
        const communicationsApi = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })

        await communicationsApi.apiBulkDistributionProjectProjectIdDelete(
          projectId,
          false, //test only
          this.getOptions(true)
        )
        return true
      } catch (error) {
        const shouldRetry = await this.HandleError(
          error,
          "Could not Delete Criteria",
          secondAttempt
        )
        if (shouldRetry)
          return await this.deleteBulkUploadProject(projectId, true)
        return false
      }
    } else {
      await sleep(100)
      return true
    }
  }

  public async getProject(
    projectId: string,
    secondAttempt: boolean = false,
    projects?: DistributionProjectAnalyticsInfoDto[]
  ): Promise<DistributionProjectInfoDto | null> {
    if (this.apiController.connected) {
      try {
        const communicationsApi = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })

        const res =
          await communicationsApi.apiBulkDistributionProjectProjectIdGet(
            projectId,
            this.getOptions(true)
          )

        return {
          ...res.data,
          scheduledDateTimeAdviser: res.data.scheduledDateTimeAdviser,
          scheduledDateTimeClient: res.data.scheduledDateTimeClient,
        }
      } catch (error) {
        const shouldRetry = await this.HandleError(
          error,
          "Could not get project",
          secondAttempt
        )
        if (shouldRetry) return await this.getProject(projectId, true)
        return null
      }
    } else {
      throw new Error("Do not use static data, use MSW instead")
    }
  }

  public async getProductTypes(
    platformName: string,
    secondAttempt: boolean = false
  ): Promise<ProductTypeDto[] | null> {
    if (this.apiController.connected) {
      try {
        const api = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })
        const response = await api.apiBulkDistributionProducttypesGet(
          this.getOptions(true)
        )
        return response.data
      } catch (error) {
        const shouldRetry = await this.HandleError(
          error,
          "Could not get sub-accounts",
          secondAttempt
        )
        if (shouldRetry) return await this.getProductTypes(platformName, true)
        return null
      }
    } else {
      await sleep(100)
      const subAccounts = [
        { name: "Collective Investment Account", code: "CIA" },
        { name: "Collective Investment Bond", code: "CIB" },
        { name: "Collective Retirement Account", code: "CRA" },
        { name: "Stocks & Shares", code: "ISA" },
        {
          name: "Junior Collective Retirement Account",
          code: "JCRA",
        },
        { name: "Junior ISA", code: "JISA" },
      ]
      // TODO: Uncomment after the FNZ Dock demo https://dev.azure.com/secure-the-file/Application/_workitems/edit/15559
      //const subAccounts = [
      //   { name: "Collective Investment Account", code: "CIA" },
      //   { name: "Collective Investment Bond", code: "CIB" },
      //   { name: "Collective Retirement Account", code: null },
      //   { name: "ISA Stocks & Shares", code: "ISA" },
      //   {
      //     name: "Junior Collective Retirement Account",
      //     code: null,
      //   },
      //   { name: "Junior ISA", code: "JISA" },
      // ]
      return subAccounts
    }
  }

  public async getClientsList(
    projectId: string,
    fileId?: string,
    version?: number,
    status?: number,
    start?: number,
    numberOfClients?: number,
    sortby?: number,
    desc?: boolean,
    search?: string,
    revokedStatus?: number,
    secondAttempt: boolean = false
  ): Promise<DistributionProjectClientListDto> {
    if (this.apiController.connected) {
      try {
        const communicationsApi = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })
        const response =
          await communicationsApi.apiBulkDistributionProjectProjectIdClientlistGet(
            projectId,
            fileId,
            version,
            status,
            start,
            numberOfClients,
            sortby,
            desc,
            search,
            revokedStatus,
            this.getOptions(true)
          )
        return response.data
      } catch (error) {
        const shouldRetry = await this.HandleError(
          error,
          "Could not get client list",
          secondAttempt
        )
        if (shouldRetry)
          return await this.getClientsList(
            projectId,
            fileId,
            version,
            status,
            start,
            numberOfClients,
            sortby,
            desc,
            search,
            revokedStatus,
            true
          )
        throw Error("Could not get client list")
      }
    } else {
      // TODO: change to throw new Error("Do not use static data, use MSW instead")
      console.error("Do not use static data, use MSW instead")
      return {}
    }
  }

  public async deleteFileBulkDistribution(
    projectID: string,
    fileIDs: Array<string>,
    secondAttempt: boolean = false
  ): Promise<any> {
    if (this.apiController.connected) {
      try {
        const bulkDistributionApi = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })

        const response =
          await bulkDistributionApi.apiBulkDistributionProjectProjectIdFileRemovePut(
            projectID,
            fileIDs,
            this.getOptions(true)
          )
        return response.data
      } catch (error) {
        const shouldRetry = await this.HandleError(
          error,
          "Could not upload",
          secondAttempt
        )
        if (shouldRetry)
          return await this.deleteFileBulkDistribution(projectID, fileIDs, true)
        const errorResponse = {
          error: (error as AxiosError).response?.data,
        }
        return [errorResponse]
      }
    }
  }

  public uploadNewFileVersion = async ({
    newVersionfile,
    projectId,
    fileId,
    secondAttempt,
    sendNotification,
  }: {
    newVersionfile: IFile
    projectId: string
    fileId: string
    secondAttempt: boolean
    sendNotification: boolean
  }) => {
    if (this.apiController.connected) {
      let wrongFileType = false
      const errorResponses = [] as IFile[]
      try {
        const bulkDistributionApi = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })
        const formData = new FormData()
        if (newVersionfile.extension === "pdf") {
          newVersionfile.name = newVersionfile.name.replace(/'/g, "`")
          const blob = new Blob([newVersionfile.binaryStr!], {
            type: newVersionfile.mimeType ?? undefined,
          })
          formData.append("file", blob, newVersionfile.name)
        } else {
          wrongFileType = true
          const errorResponse = {
            id: newVersionfile.id,
            name: newVersionfile.name,
            extension: newVersionfile.extension,
            fileType: "ERROR",
            fileName: "File type not supported",
            hasThumbnail: false,
            hasPreview: false,
          }
          errorResponses.push(errorResponse)
        }

        if (wrongFileType) {
          return errorResponses
        }

        await bulkDistributionApi.apiBulkDistributionProjectProjectIdFileFileIdVersionNewPut(
          projectId,
          fileId,
          sendNotification,
          this.getOptions(true, formData)
        )
        return await this.getProject(projectId, true)
      } catch (error) {
        const shouldRetry = await this.HandleError(
          error,
          "Could not upload",
          secondAttempt
        )
        if (shouldRetry) return null
      }
    } else {
      /**
       * The sleep is required otherwise the testing pipeline fails with the following error
       * (it fails despite the test passing):
       * ●  Cannot log after tests are done. Did you forget to wait for something async in your test?
       *    Attempted to log "Do not use static data, use MSW instead".
       */
      await sleep(1)
      // TODO: change to throw new Error("Do not use static data, use MSW instead")
      console.error("Do not use static data, use MSW instead")
      return null
    }
  }

  public async updateVersionBulkDistribution(
    projectID: string,
    fileID: string,
    newVersion: number,
    secondAttempt: boolean = false
  ): Promise<any> {
    if (this.apiController.connected) {
      try {
        const bulkDistributionApi = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })

        const response =
          await bulkDistributionApi.apiBulkDistributionProjectProjectIdFileFileIdVersionUpdateNewVersionPut(
            projectID,
            fileID,
            newVersion,
            this.getOptions(true)
          )
        return response.data
      } catch (error) {
        const shouldRetry = await this.HandleError(
          error,
          "Could not update version",
          secondAttempt
        )
        if (shouldRetry)
          return await this.updateVersionBulkDistribution(
            projectID,
            fileID,
            newVersion,
            true
          )
        const errorResponse = {
          error: (error as AxiosError).response?.data,
        }
        return [errorResponse]
      }
    } else {
      return "Not connected to server"
    }
  }

  public async revokeDocument(
    fileId: string,
    projectId: string,
    secondAttempt: boolean = false
  ) {
    if (this.apiController.connected) {
      try {
        const communicationsApi = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })

        const res =
          await communicationsApi.apiBulkDistributionProjectProjectIdFileFileIdRevokePut(
            projectId,
            fileId,
            this.getOptions(true)
          )
        return res.data
      } catch (error) {
        const shouldRetry = await this.HandleError(
          error,
          "Could not revoke document",
          secondAttempt
        )
        if (shouldRetry) return await this.getProject(projectId, true)
        return undefined
      }
    } else {
      throw new Error("Do not use static data, use MSW instead")
    }
  }

  public async deleteClientsFromProject(
    projectId: string,
    emails: string[]
  ): Promise<boolean> {
    if (this.apiController.connected) {
      try {
        const deleteApi = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })

        await deleteApi.apiBulkDistributionProjectProjectIdUsersPatch(
          projectId,
          emails,
          this.getOptions(true)
        )
        return true
      } catch (error) {
        return false
      }
    } else {
      await sleep(100)
      if (projectId && emails) {
        return true
      } else {
        return false
      }
    }
  }

  public async deleteProject(
    projectId: string,
    secondAttempt: boolean = false
  ): Promise<boolean | null> {
    if (this.apiController.connected) {
      try {
        const communicationsApi = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })

        const res =
          await communicationsApi.apiBulkDistributionProjectProjectIdDelete(
            projectId,
            false, //test only
            this.getOptions(true)
          )
        return res.status === 200
      } catch (error) {
        const shouldRetry = await this.HandleError(
          error,
          "Could not delete project",
          secondAttempt
        )
        if (shouldRetry) {
          return await this.deleteProject(projectId, true)
        }
        return false
      }
    } else {
      await sleep(100)
      return true
    }
  }

  public async revokeClientFromProject(
    projectId: string,
    userId: string,
    secondAttempt: boolean = false
  ): Promise<DistributionProjectInfoDto | null> {
    if (this.apiController.connected) {
      try {
        const communicationsApi = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })

        const res =
          await communicationsApi.apiBulkDistributionProjectProjectIdUserUserIdRevokeAllPut(
            projectId,
            userId,
            this.getOptions(true)
          )
        return res.data
      } catch (error) {
        const shouldRetry = await this.HandleError(
          error,
          "Could not revoke document",
          secondAttempt
        )
        if (shouldRetry) return await this.getProject(projectId, true)
        return null
      }
    } else {
      throw new Error("Do not use static data, use MSW instead")
    }
  }

  public async updateProjectName(
    projectId: string,
    newName: string,
    secondAttempt: boolean = false,
    projects?: DistributionProjectAnalyticsInfoDto[]
  ) {
    if (this.apiController.connected) {
      try {
        const communicationsApi = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })
        const res =
          await communicationsApi.apiBulkDistributionProjectProjectIdNamePatch(
            projectId,
            newName,
            this.getOptions(true)
          )
        return res.data
      } catch (error) {
        const shouldRetry = await this.HandleError(
          error,
          "Could not revoke document",
          secondAttempt
        )
        if (shouldRetry) return await this.getProject(projectId, true)
        return null
      }
    } else {
      await sleep(100)
      const staticProject = projects?.find(
        (staticProject) => staticProject.id === projectId
      )
      const updatedProject = {
        ...staticProject,
        name: newName,
        status: staticProject?.status,
        readCount: 0,
        sizeOfDistribution: staticProject?.sizeOfDistribution,
        sendDateAdviser: staticProject?.sendDateAdviser,
        sendDateClient: staticProject?.sendDateClient,
      } as DistributionProjectAnalyticsInfoDto
      const updatedProjects = projects?.map((x) => {
        if (x?.id && x?.id === projectId) {
          return updatedProject
        }
        return x
      })
      return updatedProjects ?? []
    }
  }

  public async putFilterCriteria(
    filterCriteria: DistributionFilterDto,
    projectId: string,
    secondAttempt: boolean = false
  ): Promise<boolean> {
    if (this.apiController.connected) {
      const bulkDistributionApi = new BulkDistributionApi({
        basePath: this.apiController.getBaseUrl,
      })

      try {
        await bulkDistributionApi.apiBulkDistributionProjectProjectIdFiltercriteriaPut(
          projectId,
          filterCriteria,
          this.getOptions(true)
        )
        return true
      } catch (error) {
        const shouldRetry = await this.HandleError(
          error,
          "Could not post filter criteria",
          secondAttempt
        )
        if (shouldRetry)
          return await this.putFilterCriteria(filterCriteria, projectId, true)
        return false
      }
    } else {
      await sleep(300)
      return true
    }
  }

  public async getClientsListFromCsv(
    projectId: string,
    start: number,
    numberOfClients: number,
    sortDesc: boolean,
    search?: string,
    secondAttempt: boolean = false
  ): Promise<DistributionClientsSearchDto> {
    if (this.apiController.connected) {
      try {
        const api = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })
        const response =
          await api.apiBulkDistributionProjectProjectIdClientsGet(
            projectId,
            start,
            numberOfClients,
            sortDesc,
            search,
            this.getOptions(true)
          )
        return response.data
      } catch (error) {
        const shouldRetry = await this.HandleError(
          error,
          "Could not get client list from CSV",
          secondAttempt
        )
        if (shouldRetry)
          return await this.getClientsListFromCsv(
            projectId,
            start,
            numberOfClients,
            sortDesc,
            search,
            true
          )
        return {}
      }
    } else {
      throw new Error("Do not use static data, use MSW instead")
    }
  }

  public async GetAllProjectsAnalyticsData(
    startDate: Date,
    endDate: Date,
    device: number,
    secondAttempt: boolean = false
  ): Promise<DistributionProjectsAnalyticsDto | null> {
    if (this.apiController.connected) {
      try {
        const api = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })

        const response = await api.apiBulkDistributionProjectsAnalyticsGet(
          startDate,
          endDate,
          device,
          this.getOptions(true)
        )
        //TODO: remove this once back end removes the 204's https://dev.azure.com/secure-the-file/Application/_workitems/edit/15317
        if (response.status === 204) {
          return {
            totalProjects: 0,
            totalDocumentsDownloaded: 0,
            totalDocumentsOpened: 0,
            totalDistributedDocuments: 0,
          } as DistributionProjectsAnalyticsDto
        }

        return response.data
      } catch (error) {
        const shouldRetry = await this.HandleError(
          error,
          "Could not get all projects analytics data",
          secondAttempt
        )
        if (shouldRetry) {
          return await this.GetAllProjectsAnalyticsData(
            startDate,
            endDate,
            device,
            true
          )
        }
        return null
      }
    } else {
      sleep(1000)
      const rangeInMonths = dayjs(endDate).diff(dayjs(startDate), "month")
      // deviceModifier is used to simulate the a change in values if the user selects mobile (0),
      // or dekstop (1) as opposed to the default "Both" option which is -1
      const deviceModifier = device === -1 ? 1 : (device + 1) * 3

      if (rangeInMonths <= 1) {
        return {
          totalProjects: 16,
          totalDocumentsDownloaded: 21 - deviceModifier,
          totalDocumentsOpened: 33 - deviceModifier,
          totalDistributedDocuments: 187 - deviceModifier,
        }
      } else if (rangeInMonths <= 3) {
        return {
          totalProjects: 49,
          totalDocumentsDownloaded: 29 - deviceModifier,
          totalDocumentsOpened: 40 - deviceModifier,
          totalDistributedDocuments: 269 - deviceModifier,
        }
      } else if (rangeInMonths <= 6) {
        return {
          totalProjects: 81,
          totalDocumentsDownloaded: 40 - deviceModifier,
          totalDocumentsOpened: 45 - deviceModifier,
          totalDistributedDocuments: 597 - deviceModifier,
        }
      } else {
        return {
          totalProjects: 100,
          totalDocumentsDownloaded: 60 - deviceModifier,
          totalDocumentsOpened: 65 - deviceModifier,
          totalDistributedDocuments: 800 - deviceModifier,
        }
      }
    }
  }

  public async GetAllProjectsAnalyticsDatesData(
    startDate: Date | undefined,
    endDate: Date,
    device: number,
    timePeriod: number,
    secondAttempt: boolean = false
  ): Promise<DistributionProjectDatesAnalyticsDto | null> {
    if (this.apiController.connected) {
      try {
        const api = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })

        const response = await api.apiBulkDistributionProjectsAnalyticsDatesGet(
          startDate,
          endDate,
          device,
          timePeriod,
          this.getOptions(true)
        )
        return response.data
      } catch (error) {
        const shouldRetry = await this.HandleError(
          error,
          "Could not get all projects analytics data",
          secondAttempt
        )
        if (shouldRetry) {
          return await this.GetAllProjectsAnalyticsDatesData(
            startDate,
            endDate,
            device,
            timePeriod,
            true
          )
        }
        return null
      }
    } else {
      sleep(1000)
      if (device !== -1) {
        let deviceFilteredDate = {
          distributionProjectDateAnalyticsDtos: [
            ...graphDataStatic.distributionProjectDateAnalyticsDtos.filter(
              (_, i) => (i + device) % 2 === 0
            ),
          ],
        }
        return deviceFilteredDate
      }
      return graphDataStatic
    }
  }

  public async updateProjectVisibility(
    projectId: string,
    newVisibility: string,
    secondAttempt: boolean = false
  ): Promise<DistributionProjectInfoDto | null> {
    if (this.apiController.connected) {
      try {
        const api = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })
        const response =
          await api.apiBulkDistributionProjectProjectIdVisibilityNewVisibilityPatch(
            projectId,
            newVisibility,
            this.getOptions(true)
          )
        return response.data
      } catch (error) {
        const shouldRetry = await this.HandleError(
          error,
          "Could not update project visibility",
          secondAttempt
        )
        if (shouldRetry)
          return await this.updateProjectVisibility(
            projectId,
            newVisibility,
            true
          )
        return null
      }
    } else {
      throw new Error("Do not use static data, use MSW instead")
    }
  }

  public async getCampaignReport(
    projectId: string,
    filterValues: CampaignReportFilterValues
  ): Promise<DistributionCampaignReportDto | null> {
    if (this.apiController.connected) {
      try {
        const api = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })

        const res =
          await api.apiBulkDistributionProjectProjectIdCampaignreportGet(
            projectId,
            new Date(filterValues.startDate),
            new Date(filterValues.endDate),
            filterValues.fileId,
            filterValues.version,
            filterValues.device,
            filterValues.userType,
            this.getOptions(true)
          )

        const campaignReport = {
          ...res.data,
          firstOpened: res.data.firstOpened,
          lastOpened: res.data.lastOpened,
        }

        return campaignReport
      } catch (error) {
        await this.HandleError(error, "Could not get campaign report")
        return null
      }
    } else {
      sleep(1000)
      // userType can be 0 for all, "1" for clients, or "2" for advisors, hence the == check
      // instead of ===
      if (filterValues.userType == 1) {
        return dummyDistributionCampaignReportClient
      } else if (filterValues.userType == 2) {
        return dummyDistributionCampaignReportAdvisor
      } else {
        return dummyDistributionCampaignReportAll
      }
    }
  }

  public async updateProjectDistributionDetails(
    projectId: string,
    storeDistributionDetailsDto: StoreDistributionDetailsDto,
    secondAttempt: boolean = false,
    projects?: DistributionProjectAnalyticsInfoDto[]
  ): Promise<boolean | DistributionProjectInfoDto> {
    if (this.apiController.connected) {
      try {
        const communicationsApi = new BulkDistributionApi({
          basePath: this.apiController.getBaseUrl,
        })

        await communicationsApi.apiBulkDistributionProjectProjectIdSaveprojectdetailsPut(
          projectId,
          storeDistributionDetailsDto,
          this.getOptions(true)
        )
        return true
      } catch (error) {
        const shouldRetry = await this.HandleError(
          error,
          "Could not update project details",
          secondAttempt
        )
        if (shouldRetry)
          return await this.updateProjectDistributionDetails(
            projectId,
            storeDistributionDetailsDto,
            true,
            projects
          )
        return false
      }
    } else {
      await sleep(500)
      const updateStaticProject = projects?.find(
        (project) => project.id === projectId
      )
      if (updateStaticProject) {
        return updateStaticProject
      } else {
        const newProject = {
          id: projectId,
          distributionDateTimeAdviser:
            storeDistributionDetailsDto.distributionDateTimeAdviser,
          distributionDateTimeClient:
            storeDistributionDetailsDto.distributionDateTimeClient,
          isSubAccount: storeDistributionDetailsDto.isSubAccount,
          sendNotification: storeDistributionDetailsDto.sendNotification,
          visibility: storeDistributionDetailsDto.visibility,
        } as DistributionProjectInfoDto
        return newProject
      }
    }
  }

  /*Private helpers */
  private getOptions(withToken: boolean, formData: FormData = new FormData()) {
    type OptionValues = { [key: string]: any }
    type HeaderValues = { [key: string]: string }

    const headerValues: HeaderValues = {}
    if (this.apiController.getApiConnection.apiKey) {
      headerValues["ApiKey"] = this.apiController.getApiConnection.apiKey
    }

    if (withToken) {
      headerValues["Authorization"] = "Bearer " + this.apiController.getApiToken
    }

    const optionValues: OptionValues = {}
    optionValues["headers"] = headerValues
    optionValues["data"] = formData
    optionValues["Content-Type"] = "multipart/form-data"

    return optionValues
  }
}
