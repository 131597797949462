import { FunctionComponent } from "react"
import { useApplicationContext } from "../../contexts/application/context"
import AmadeusFooterLogo from "../../design-tokens/imgs/amadeus/footerLogo"
import { BarclaysFooter } from "../../design-tokens/imgs/barclays/barclaysFooter"
import { BarclaysFooterMobile } from "../../design-tokens/imgs/barclays/barclaysFooterMobile"
import { BarclaysFooterTablet } from "../../design-tokens/imgs/barclays/barclaysFooterTablet"
import FortCirrusFooterLogo from "../../design-tokens/imgs/fortCirrus/footerLogo"
import { LifestageMobileLogo } from "../../design-tokens/imgs/lifestage/lifestageMobileLogo"
import { MoneyhubFooterMobile } from "../../design-tokens/imgs/moneyhub/moneyhubFooterMobile"
import { MoneyhubFooterTablet } from "../../design-tokens/imgs/moneyhub/moneyhubFooterTablet"
import { SchofieldSweeneyMobileLogo } from "../../design-tokens/imgs/schofieldSweeney/schofieldSweeneyMobileLogo"
import { TFEFooterLogoSmall } from "../../design-tokens/imgs/TFEFooterSmall"
import LegadoLogo, { LogoVariant } from "../../images/LegadoLogo"
import { pageString } from "../../utils/helpers"
import "./Footer.css"
import { WpsAdvisoryMobileNavLogo } from "../../design-tokens/imgs/wpsAdvisory/wpsAdvisoryMobileNavLogo"

export const Footer = () => {
  const {
    applicationState: { selectedTheme },
  } = useApplicationContext()
  return (
    <div className="legado-footer">
      <div className="container">
        {selectedTheme === "SCOTTISH_WIDOWS"
          ? `
          Scottish Widows Limited. Registered in England and Wales No. 3196171.
          Registered office in the United Kingdom at 25 Gresham Street, London
          EC2V 7HN. Telephone: 0131 655 6000. Scottish Widows Limited is
          authorised by the Prudential Regulation Authority and regulated by the
          Financial Conduct Authority and the Prudential Regulation Authority.
          Financial Services Register number 181655. © Copyright Scottish Widows
          1998 onwards, all rights reserved.
        `
          : selectedTheme === "removedForNow"
          ? `
          FNZ (UK) LTD. Registered in England and Wales No. 05435760.
          Registered office in the United Kingdom at Suite 1, 3rd Floor 11-12 St. 
          James's Square, London, United Kingdom, SW1Y 4LB. FNZ (UK) LTD Limited is
          authorised by the Prudential Regulation Authority and regulated by the
          Financial Conduct Authority and the Prudential Regulation Authority.
          Financial Services Register number 181655. © Copyright FNZ (UK) LTD
          2005 onwards, all rights reserved.
        `
          : ""}
      </div>
    </div>
  )
}

export const FooterBarclays = () => {
  return (
    <>
      <div className="hide-on-tablet-and-down">
        <img
          src={BarclaysFooter.img}
          alt={"BarclaysFooter-Desktop"}
          className="barclaysFooter"
        ></img>
      </div>
      <div className="hide-on-desktop-only hide-on-mobile-only">
        <img
          src={BarclaysFooterTablet.img}
          alt={"BarclaysFooter-Tablet"}
          className="barclaysFooter barclays-footer-tablet"
        ></img>
      </div>
      <div className="hide-on-tablet-and-up">
        <img
          src={BarclaysFooterMobile.img}
          alt={"BarclaysFooter-Mobile"}
          className="barclaysFooterMobile"
        ></img>
      </div>
    </>
  )
}

export const FooterMoneyhub = () => {
  return (
    <>
      <div className="hide-on-desktop-only hide-on-mobile-only">
        <img
          src={MoneyhubFooterTablet.img}
          className="moneyhub-footer-mobile"
        ></img>
      </div>
      <div className="hide-on-tablet-and-up">
        <img
          src={MoneyhubFooterMobile.img}
          className="moneyhub-footer-mobile"
        ></img>
      </div>
    </>
  )
}

export const LegadoFooter = () => {
  return (
    <>
      <div
        className="legado logoFooter"
        data-testid={pageString() + "-logoFooter-div footer-frame-right-logo"}
      >
        <LegadoLogo variant={LogoVariant.ICON_ONLY_DARK} />
      </div>
    </>
  )
}

export const TFEFooterSmall = () => {
  return (
    <>
      <div className="logoTFEFooter">
        <TFEFooterLogoSmall />
      </div>
    </>
  )
}

export const DocumentHubOrLibraryFooter: FunctionComponent<{
  text?: string
}> = ({ text }) => (
  <div className="document-hub-library-footer container" data-testid="footer">
    {text && <p className="document-hub-library-footer-text">{text}</p>}
  </div>
)

export const FortCirrusFooter = () => {
  return (
    <>
      <div className="legado logoFooter">
        <FortCirrusFooterLogo variant={LogoVariant.ICON_ONLY_DARK} />
      </div>
    </>
  )
}

export const AmadeusFooter = () => {
  return (
    <>
      <div className="legado logoFooter">
        <AmadeusFooterLogo />
      </div>
    </>
  )
}

export const LifeStageFooter = () => {
  return (
    <>
      <div className="legado logoFooter">
        <LifestageMobileLogo />
      </div>
    </>
  )
}

export const WpsAdvisoryFooter = () => {
  return (
    <>
      <div className="legado logoFooter">
        <WpsAdvisoryMobileNavLogo />
      </div>
    </>
  )
}

export const ScholfieldSweeneyFooter = () => {
  return (
    <>
      <div className="legado logoFooter">
        <SchofieldSweeneyMobileLogo />
      </div>
    </>
  )
}
