// TODO: remove this once we have the correct types
/* eslint-disable @typescript-eslint/no-explicit-any */
import ReactGA from "react-ga4"

import { IUser } from "../../api/api-client/api-types"
import { userRolesToGARoles } from "../../data/google-analytics/helpers"

export const initializeGA = ({
  connectionType,
  gaTrackingId,
  currentUser,
  platform,
  themeFromUrl,
}: {
  connectionType: "SSO" | "developer" | "static"
  gaTrackingId: string
  currentUser?: IUser
  platform?: any
  themeFromUrl?: string
}) => {
  if (!gaTrackingId) {
    return
  }
  try {
    ReactGA.initialize(gaTrackingId, {
      testMode:
        !process.env.NODE_ENV ||
        process.env.NODE_ENV === "development" ||
        process.env.NODE_ENV === "test",
      gaOptions: {
        cookieDomain: "none",
        Organisation_ID: currentUser?.organisationId ?? "No Organisation",
        user_Role:
          userRolesToGARoles(currentUser?.roles?.join()) ?? "No User Role",
        login_type_tmp: connectionType,
        corporate_platform: themeFromUrl
          ? themeFromUrl.toLowerCase()
          : platform || "No Platform",
      },
    })
  } catch (error) {
    console.error("Error initializing GA:", error)
  }
}
